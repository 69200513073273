$FontSans: 'Source Sans Pro', sans-serif;
$bgcolor: #f7f9fa;
$white: #ffffff;
$black: #000000;
$TxtGray: #374347;
$mid-grey: #b3c6cd;
$red: #f5474a;
$red_btn: #f84545;
$green: #44b5ab;
$frenchPass: #b3eafb;
$blackSqueeze: #e1eef3;
$bonjour: #e7e4e5;
$limedSpruce: #364347;
$grey: #666666;
$greyC: #cccccc;
$greyE6: #e6e6e6;
$greyA7: #a7a7a7;
$heather: #b2c6cd;
$grayB0: #b0b0b0;
$greyA776: #a7a7a6;
$lighter-blue: #d6e1e5;
$light-blue: #02baf2;
$dark-blue: #199ecb;
$hit_gray: #93a4aa;
$maroon: #9c0e17;
$darker-red: #bb0a30;
$charcoalGrey: #4a4a4a;
$heather: #b2c6cd;
$castWhite: #e2eef3;
$greenShade0: #009a44;
$greenShade1: #3eb5ac;
$black40opac: rgba(0, 0, 0, 0.4);
$azureBlue: #0478ff;
$dark-grey: #a9a9a9;

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  transition: background-color 0.5s;
  font-family: $FontSans;
  background: $white; /*background:$bgcolor;*/
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  outline: none;
}
//.anim { transition: all 0.4s ease; -webkit-transition: all 0.4s ease; -ms-transition: all 0.4s ease; -moz-transition: all 0.4s ease; -o-transition: all 0.4s ease; }
.container {
  max-width: 768px;
}

.standard_line_height * {
  line-height: 120% !important;
}

.txtBlack {
  color: $black !important;
}
.TxtRed {
  color: $red !important;
}

p {
  margin: 0 0 10px 0;
  line-height: 130%;
}

.p0 {
  padding: 0 !important;
}
.pl0 {
  padding-left: 0 !important;
}
.pr0 {
  padding-right: 0 !important;
}
.pr20 {
  padding-right: 20px !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pt8 {
  padding-top: 8px !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pb70 {
  padding-bottom: 70px !important;
}
.pb112 {
  padding-bottom: 112px !important;
}
.pb125 {
  padding-bottom: 125px !important;
}
.pt5 {
  padding-top: 5px !important;
}
.pt15 {
  padding-top: 15px !important;
}
.pt70 {
  padding-top: 70px !important;
}
.pt55 {
  padding-top: 55px !important;
}

.m0 {
  margin: 0 !important;
}
.ml0 {
  margin-left: 0 !important;
}

.ml10 {
  margin-left: 10px !important;
}

.mr0 {
  margin-right: 0 !important;
}
.mr40 {
  margin-right: 40px !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt55 {
  margin-top: 55px !important;
}
.mt70 {
  margin-top: 70px !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}

.gap10 {
  height: 10px;
}
.gap20 {
  height: 20px;
}
.gap30 {
  height: 30px;
}
.gap40 {
  height: 40px;
}
.gap50 {
  height: 50px;
}

.clear {
  clear: both;
}

.mappin-button {
  position: fixed;
  bottom: 0px;
  width: 100%;
  transition: 0.5s;
}
.mappin-button.height.contacting {
  height: 230px;
}
.mappin-button.height {
  height: 180px;
}
.mappin-bicon {
  background: url(./assets/Images/Map-carpin.svg) no-repeat center;
  height: 86px;
  width: 82px;
  margin: 0 auto;
  box-shadow: 0px -8px 17px -4px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  text-align: center;
}

.map-innerdiv {
  float: left;
  background: $white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  min-height: 50px;
  max-height: 430px;
  padding: 30px 0 15px 0;
  opacity: 1;
  overflow: scroll;
}
.mappin-bicon img {
  border-radius: 1000px;
  background-size: cover;
  width: 52px;
  height: 52px;
  margin: 14px 0;
}
.map-detail-link1,
.map-detail-link2 {
  position: absolute;
  right: 15px;
  top: 53%;
  font-size: 13px;
  cursor: pointer;
  color: $black;
  font-weight: bold;
}
.map-detail-link2 {
  display: none;
}
.mappin-section {
  outline: none;
  margin: 0;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  padding: 20px 0;
  border-bottom: 1px solid $heather;
  height: 64px;
  z-index: 1;
}
header .container {
  padding: 0 10px;
}
.header-btn,
header h1 {
  font-size: 18px;
  color: $white;
}
.header-btn {
  background: none;
  border: none;
  width: 20%;
  display: inline-block;
  text-align: left;
  padding: 0;
}
header h1 {
  width: 60%;
  display: inline-block;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 24px;
}
header .left {
  width: 20%;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
header .right {
  width: 20%;
  text-align: right;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
header .lnk {
  font-size: 17px;
  color: $light-blue;
  margin: 0;
  line-height: 24px;
}
header .lnk:hover {
  color: $TxtGray;
  text-decoration: none;
}
header .title {
  width: 60%;
  float: left;
  margin: 0 20%;
  font-size: 17px;
  line-height: 28px;
  color: $TxtGray;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
header .back_arrow {
  float: left;
}
header .back_arrow img {
  float: left;
}

.borderTopGray {
  border-top: 1px solid $mid-grey;
}

.infotext {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 20px;
  color: $grey;
  font-size: 16px;
  line-height: 120%;
  padding: 0 20px;
}
.noscroll {
  overflow: initial !important;
}
.heightAuto {
  height: auto !important;
}
.thumb_Initial {
  width: 40px;
  height: 40px;
  background: $castWhite;
  float: left;
  margin-right: 0;
  border-radius: 50%;
  font-weight: 600;
  font-size: 18px;
  color: $hit_gray;
  text-align: center;
  line-height: 40px;
}
.thumb_Initial .fa {
  font-size: 18px;
  color: $hit_gray;
  line-height: 40px;
}

.getHelp {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.97);
}
.getHelp .topbar {
  width: 100%;
  float: left;
  margin: 0 0 5px 0;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 105;
}
.getHelp .topbar .title {
  width: 70%;
  float: left;
  padding: 15px;
  margin: 0 15%;
  font-size: 24px;
  font-weight: 600;
  color: $limedSpruce;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.getHelp .topbar .close {
  position: absolute;
  top: 15px;
  right: 12px;
  opacity: 1;
}
.getHelp .topbar .close a .fa {
  color: $greyC;
}
.getHelp .topbar .close a .fa:hover {
  color: $grey;
}

.getHelp .bottom_fix {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: $white;
  padding: 10px 20px;
}
.volvo-app .getHelp .bottom_fix {
  padding: 20px;
}

.getHelp.ios .topbar .title {
  padding-top: 30px;
}
.getHelp.ios .topbar .close {
  top: 30px;
}

.progress {
  width: 100%;
  height: 24px;
  margin: 0;
  overflow: hidden;
  padding: 5px 5px;
  background-color: $lighter-blue;
  border-radius: 40px;
  -webkit-box-shadow: inset 0 0px 0px transparent;
  box-shadow: inset 0 0px 0px transparent;
  position: relative;
  z-index: 111;
}
.volvo-app .progress {
  width: 79%;
  height: 3px;
  margin: 0 auto;
  border-radius: 0;
  padding: 0;
}
.progress-bar {
  width: 56%;
  float: left;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: $white;
  text-align: center;
  background: $greenShade0;
  -webkit-box-shadow: inset 0 0px 0 transparent;
  box-shadow: inset 0 0px 0 transparent;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
  border-radius: 20px;
}

.assistance {
  width: 100%;
  float: left;
  margin: 0;
  font-size: 17px;
  font-weight: 400;
}
.assistance a {
  font-weight: 700;
  color: $light-blue;
}

.mainContent {
  width: 100%;
  float: left;
  padding: 50px 12px 70px 12px;
}

.chatBox {
  width: 100%;
  float: left;
  padding: 15px 0;
}
.chatBox .sender {
  width: 100%;
  float: left;
  margin: 15px 0;
  position: relative;
  text-align: left;
}
.chatBox .sender .profile {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  overflow: hidden;
  background: $light-blue;
}
.chatBox .sender .profile img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.chatBox .sender .chats {
  width: 100%;
  float: left;
  padding-left: 60px;
}
.volvo-app .chatBox .sender .chats {
  max-width: 85%;
  padding-left: 0;
}
.chatBox .sender .chats .msg {
  float: none;
  display: inline-block;
  padding: 8px 10px;
  line-height: 140%;
  color: $limedSpruce;
  border: 1px solid $heather;
  background: $white;
  clear: both;
  margin: 0 10px 10px 0;
  border-radius: 2px 8px 8px 2px;
}
.chatBox .sender .chats .msg:first-child {
  border-top-left-radius: 8px;
}
.chatBox .sender .chats > .msg:last-child {
  margin-bottom: 0;
  border-bottom-left-radius: 8px;
}

.chatBox .receiver {
  width: 100%;
  float: left;
  margin: 15px 0;
  position: relative;
  text-align: right;
}
.chatBox .receiver .chats {
  width: 100%;
  float: left;
}
.chatBox .receiver .chats a.msg {
  min-width: 60px;
  max-width: 90%;
  text-align: center;
  color: $limedSpruce;
  text-decoration: none;
  float: none;
  display: inline-block;
  padding: 9px 10px;
  line-height: 140%;
  background: $lighter-blue;
  clear: both;
  margin: 0 0 10px 10px;
  border-radius: 8px;
  word-break: break-word;
}
.chatBox .receiver .chats a.msg.noClear {
  clear: none;
}
.chatBox .receiver .chats a.msg.noClear .number {
  text-decoration: underline;
}
.chatBox .receiver .chats a.msg:hover {
  /*background:$greyC;*/
}
.chatBox .receiver .revise_btn {
  font-size: 13px;
  font-weight: 600;
  color: $light-blue;
  text-transform: uppercase;
  float: none;
  clear: both;
  padding: 0 8px;
  position: relative;
  top: -4px;
}
.chatBox .receiver .chats .input {
  float: right;
  border: 0;
  border-bottom: 1px solid $heather;
  padding: 9px 0;
  width: 84%;
  color: $black;
}
.chatBox .receiver .chats .addressBox {
  width: 100%;
  float: left;
}
.chatBox .receiver .chats .addressBox .left_info {
  float: left;
  width: 75%;
  padding: 0 10px 0 0;
  border-right: 1px solid $hit_gray;
  text-align: left;
}
.chatBox .receiver .chats .addressBox .left_info span {
  width: 100%;
  float: left;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.chatBox .receiver .chats .addressBox .info_box {
  float: left;
  width: 100%;
  padding: 0 10px 0 0;
  text-align: left;
}
.chatBox .receiver .chats .addressBox .info_box span {
  width: 100%;
  float: left;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.chatBox .receiver .chats .addressBox .right_info {
  float: left;
  width: 25%;
}
.chatBox .receiver .chats .addressBox .right_info span {
  width: 100%;
  float: left;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.chatBox .receiver .chats .mapBox {
  float: right;
  width: 185px;
  border-radius: 8px;
  margin: 0 0 10px 0;
}
.chatBox .receiver .chats .isMap {
  width: 100%;
  float: left;
  height: 120px;
  border: 1px solid $heather;
  background-position: 50% 50% !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  border-radius: 8px 8px 0 0;
}
.chatBox .receiver .chats .caption {
  width: 100%;
  float: left;
  padding: 10px 15px;
  border-radius: 0 0 8px 8px;
  line-height: 120%;
  background: $lighter-blue;
  color: $limedSpruce;
  text-align: left;
}
.chatBox .receiver .chats ::placeholder {
  color: $heather;
}
.chatBox .receiver .chats :-ms-input-placeholder {
  color: $heather;
}
.chatBox .receiver .chats ::-ms-input-placeholder {
  color: $heather;
}

.search_box {
  width: 100%;
  float: left;
  border-bottom: 1px solid $greyA7;
  padding: 9px 10px;
}
.search_box .frm {
  width: 100%;
  float: left;
}
.search_box input[type='search'] {
  width: 100%;
  float: left;
  padding: 7px 15px 8px 40px;
  font-weight: 300;
  color: $greyA7;
  font-size: 20px;
  border: 0;
}
.search_box .fa {
  width: 40px;
  height: 42px;
  position: absolute;
  color: $greyA7;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
}

.list_items {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
}
.list_items ul {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
}
.list_items ul li {
  width: 100%;
  float: left;
  margin: 0;
  padding: 16px 20px 16px 20px;
  font-size: 20px;
  line-height: 140%;
  position: relative;
}
.list_items ul li:before {
  width: 20px;
  height: 1px;
  background: $white;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
}
.list_items ul li:after {
  width: 100%;
  height: 1px;
  background: $greyA7;
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
}
.list_items ul li .item_name {
  width: 100%;
  float: left;
  padding-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.list_items ul li .icon {
  width: 40px;
  height: 49px;
  position: absolute;
  top: 0;
  right: 0;
}
.list_items ul li .icon.checked {
  background: url('assets/Images/Check-blue.svg') no-repeat 50% 50%;
}

.loader {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  transition: 1s all ease;
  background: rgba(0, 0, 0, 0.1);
}
.loader * {
  transition: 1s all ease;
}
.loader.show {
  opacity: 1;
  z-index: 1000;
}
.loaderbg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  background: rgba(0, 0, 0, 0.4);
}
.loaderbg .loader_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.loaderbg .loader_content .loaderText {
  color: #fff;
  animation: blink 2s linear infinite;
}
.loaderbg .loader_content .loaderimg {
  width: 70px;
  height: 70px;
  display: inline-block;
}
.loaderbg .loader_content .loaderimg img {
  width: 100%;
  opacity: 0.5;
}

.popShow {
  animation: fadein 1s;
}

.pg_search_list {
  width: 100%;
  float: left;
  display: none;
  position: fixed;
  overflow-y: auto;
  z-index: 110;
  background: $white;
  top: 0;
  left: 0;
  height: 100%;
}
.pg_search_list.ios {
  top: 20px;
}
.pg_search_list.ios .header {
  top: 20px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#mapContainer {
  width: 100%;
  // height: calc(100% - 185px);
  height: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
}

.box_search {
  width: 100%;
  float: left;
  padding: 10px;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 10;
}
.box_search .white-txtbg {
  transition: all 0.4s ease !important;
  margin: 0 0 10px 0;
  border-radius: 4px;
  border: 1px solid $lighter-blue;
}
.box_search .vehicle-row-container {
  background: transparent;
}
.box_search input[type='search'] {
  width: 100%;
  border: 1px solid $lighter-blue;
  font-size: 20px;
  font-weight: 300;
  color: $limedSpruce;
  padding: 25px 30px 15px 40px;
  height: 46px;
  border-radius: 4px;
}
.box_search input[type='search'] + label {
  font-size: 16px;
  top: 14px;
  left: 40px;
  position: absolute;
  color: $mid-grey;
}
.box_search input:focus + label {
  font-size: 14px;
  top: 4px;
}
.box_search input:valid + label {
  font-size: 14px;
  top: 4px;
}
.box_search .fa-search {
  position: absolute;
  top: 15px;
  left: 12px;
  color: $limedSpruce;
  font-size: 18px;
}
.box_search .has-float-label input:placeholder-shown:not(:focus)::placeholder {
  color: $mid-grey;
  opacity: 1 !important;
}
.box_search .has-float-label.nolabel input[type='search'] {
  padding: 20px 30px 20px 40px;
}
.box_search .clear {
  position: absolute;
  top: 16px !important;
  right: 14px;
}
.box_search .clear:hover .fa {
  color: $black;
}
.box_search input[type='search']:focus::placeholder {
  color: $mid-grey;
}

.has-float-label {
  display: block;
  position: relative;
}
// .box_search.withCancel { top:0; height: 100%; padding-top: 30px; }
.box_search.withCancel .has-float-label input[type='search'] {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border: 0;
}
.box_search.withCancel .has-float-label {
  width: 77%;
  float: left;
}
.box_search.withCancel .cancel {
  width: 20%;
  margin-left: 3%;
  float: left;
  padding: 15px 0px;
  color: $black;
  background: $white;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  text-decoration: none !important;
}
.box_search.withCancel .cancel:hover {
  text-decoration: none !important;
  background: $lighter-blue;
  color: $black;
}
.box_search .form-group {
  width: 100%;
  float: left;
  margin: 0 0 20px 0;
}
.box_search .detail_box {
  width: 100%;
  float: left;
  margin: 0 0 20px 0;
  background: $white;
  padding: 14px 25px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}
.box_search .detail_box p {
  color: $charcoalGrey;
  font-size: 15px;
  text-align: center;
}
.box_search .detail_box.text-area {
  border-radius: 0;
  padding: 0 0 0 20px;
  border: 0;
}
.box_search .detail_box.text-area .reg-title {
  font-size: 16px;
}
.box_search .detail_box.text-area .reg-desc {
  font-size: 13px;
  margin: 0 !important;
}
.box_search .detail_box.text-area .miles-area h1 {
  font-size: 16px;
}
.box_search .detail_box.text-area .miles-area span {
  font-size: 13px;
  margin: 0 !important;
}

/*
.pac-container .pac-item { padding: 10px 20px !important; border-top: 0 !important; position: relative; }
.pac-container .pac-item:after { position: absolute; bottom:0; right:0; width:95%; height: 1px; content:''; background: $greyE6; }
.pac-container .pac-item:last-child:after { height: 0; }
.pac-container .pac-item .pac-icon { display: none !important; }
.pac-container .pac-item .pac-item-query { width:100%; float:left; line-height: 16px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
.pac-container .pac-item span:nth-child(3) { width:100%; float:left; line-height: 16px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
*/

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  padding: 15px 0;
  border-bottom: 1px solid $greyA7;
  height: 55px;
  z-index: 1;
}
.header .container {
  padding: 0 10px;
}
.header h2 {
  width: 60%;
  display: inline-block;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 24px;
}
.header .left {
  width: 20%;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.header .right {
  width: 20%;
  text-align: right;
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 17px;
  color: $light-blue;
  font-weight: 600;
  transform: translateY(-50%);
}
.header .lnk {
  font-size: 18px;
  color: $black;
  margin: 0;
  line-height: 24px;
}
.header .lnk:hover {
  color: $black;
}
.header .lnk.disabled {
  color: $grayB0;
}
.header .title {
  width: 60%;
  float: left;
  margin: 0 20%;
  font-size: 20px;
  line-height: 24px;
  color: $limedSpruce;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.header .back_arrow {
  float: left;
}
.header .back_arrow img {
  float: left;
}

.width100Per.msg {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.bottom_drawer * {
  box-sizing: border-box;
}
.bottom_drawer {
  width: 100%; /*min-height: 200px;*/ /*max-height: 360px;*/
  position: fixed;
  bottom: 0;
  left: 0;
  background: $white;
  z-index: 11;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
.bottom_drawer .center_icon_base {
  position: absolute;
  top: 0;
  left: 50%;
  width: 80px;
  height: 80px;
  background: $white;
  z-index: 10;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
.bottom_drawer .drawer_base {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
  z-index: 11;
  background: $white;
  padding: 55px 0 20px 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.bottom_drawer .center_icon {
  position: absolute;
  z-index: 100;
  top: -33px;
  left: 50%;
  transform: translateX(-50%);
}
.bottom_drawer .drawer_base .wrapper {
  width: 100%;
  max-width: 480px; /*max-height: 300px;*/
  margin: 0 auto;
  padding: 0;
  overflow-y: auto;
  margin-bottom: 70px;
}
.bottom_drawer .drawer_base .wrappertxt {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 0 20px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.bottom_drawer .drawer_base .top_link {
  position: absolute;
  top: 18px;
  right: 20px;
}
.bottom_drawer .drawer_base .top_link a {
  color: $light-blue;
  padding: 0 0 5px 5px;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
}
.bottom_drawer .drawer_base .top_link a:hover {
  text-decoration: underline;
}
.bottom_drawer .drawer_base .textbox {
  width: 100%;
  float: left;
}
.bottom_drawer .drawer_base input[type='text'] {
  padding: 0 !important;
  color: $hit_gray;
}
.bottom_drawer .drawer_base ::-webkit-input-placeholder {
  color: $hit_gray;
}
.bottom_drawer .drawer_base ::-moz-input-placeholder {
  color: $hit_gray;
}
.bottom_drawer .drawer_base ::-ms-input-placeholder {
  color: $hit_gray;
}
.bottom_drawer .drawer_base ::-o-input-placeholder {
  color: $hit_gray;
}

.bottom_drawer.cancel .drawer_base {
  padding: 20px 0;
}

.bottom_drawer textarea {
  width: 100%;
  height: 75px !important;
  border: 1px solid $lighter-blue !important;
  margin: 15px 0 !important;
  border-radius: 4px !important;
  resize: none;
}

.bottom_fix {
  text-align: center;
  padding: 15px 20px;
  box-sizing: border-box;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: $white;
}
.bottom_fix.base_bg {
  background: #f7f9fa;
}
.bottom_fix .wrapper {
  margin-bottom: 0 !important;
  padding: 0 !important;
}
.bottom_fix .btn {
  width: 100%;
  border-radius: 4px;
  padding: 12px 15px;
  background: $light-blue;
  box-sizing: border-box;
  text-decoration: none;
  color: $white;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.bottom_fix .btn.disabled {
  background: $frenchPass;
}
.bottom_fix .btn:hover {
  background: $blackSqueeze;
  color: $limedSpruce;
}
.bottom_fix .btn.dark {
  background: $limedSpruce;
  color: $white;
}
.bottom_fix .btn.dark:hover {
  background: $blackSqueeze;
  color: $limedSpruce;
}
.bottom_fix .btn.black {
  background: $limedSpruce;
  color: $white;
}
.bottom_fix .btn.black:hover {
  background: $blackSqueeze;
  color: $limedSpruce;
}
.bottom_fix .btnClose {
  width: 100%;
  border-radius: 4px;
  padding: 14px 13px;
  background: $white;
  box-sizing: border-box;
  text-decoration: none;
  color: $white;
  display: inline-block;
  font-size: 20px;
  font-weight: 500 !important;
  border: 1px solid transparent;
}
.bottom_fix .btnClose:hover {
  background: $blackSqueeze !important;
  color: $limedSpruce !important;
}
.bottom_fix .btnClose.blue {
  background-color: $light-blue;
}
.bottom_fix .btnClose.red {
  background-color: $red_btn;
}
.bottom_fix .btnClose.btn_outline {
  border: 1px solid #d6e1e5;
  color: $limedSpruce;
  background-color: $white;
  font-weight: 600 !important;
}
.bottom_fix .btnClose.contact_us {
  border: 1px solid #d6e1e5;
  color: $light-blue;
  background-color: $white;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.bottom_fix .btnClose.half {
  width: 49%;
}
.bottom_fix .btnClose.half.left {
  margin-right: 1%;
}
.bottom_fix .btnClose.half.right {
  margin-left: 1%;
}
.bottom_fix.btn-disable {
  background: none !important;
}
.progress_box {
  width: 100%;
  float: left;
  text-align: center;
  padding: 0 20px;
  margin: 0 0 15px 0;
}
.progress_box .title {
  width: 100%;
  font-size: 20px;
  line-height: 26px;
  color: $limedSpruce;
}
.progress_box .subtitle {
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  color: $hit_gray;
  font-style: italic;
}
.progress_bar {
  width: 100%;
  max-width: 80% !important;
  background: $blackSqueeze;
  height: 3px;
  margin-top: 18px;
  margin-left: auto;
  margin-right: auto;
}
.volvo-app .progress_bar {
  height: 3px;
  padding: 0;
  border-radius: 0;
  float: none;
  display: inline-block;
}
.progress_bar .progress_meter {
  max-width: 100% !important;
  float: left;
  background: $light-blue;
  height: 3px;
}

.list_data {
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 0 0 12px;
}
.list_data .row {
  width: 100%;
  float: left;
  padding: 10px 0;
  position: relative;
  overflow-x: hidden;
  margin: 0;
}
.list_data .row:after {
  content: '';
  width: 93%;
  height: 1px; /*background: $mid-grey;*/
  background: $heather;
  position: absolute;
  bottom: 0;
  left: 50px;
}
.list_data .row.last:after {
  height: 0;
}
.list_data .thumb_Initial {
  width: 40px;
  height: 40px;
  background: $castWhite;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 18px;
  color: $hit_gray;
  text-align: center;
  line-height: 40px;
  background-position: 50% 50% !important;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
}
.list_data .thumb_Initial .fa {
  font-size: 18px;
  color: $mid-grey;
  line-height: 40px;
}
.list_data .icon {
  width: 40px;
  height: 40px;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 18px;
  color: $hit_gray;
  text-align: center;
  line-height: 39px;
}
.list_data .icon img {
  max-width: 40px;
  max-height: 40px;
}
.volvo-app .list_data .icon.volvoIcon img {
  max-width: 40px;
  max-height: initial;
  width: 26px;
}
.list_data .details {
  float: left;
  width: 83%;
  max-width: 400px;
  padding: 0;
}
.list_data .details .name {
  color: $limedSpruce;
  font-size: 20px;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  width: 96%;
}
.list_data .details .value {
  color: $hit_gray;
  font-size: 15px;
  line-height: 19px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  width: 96%;
}
.list_data .details .link {
  color: $hit_gray;
  font-size: 20px;
  font-weight: 300;
  padding: 5px 0 0 0;
}
.list_data .details .price {
  margin-top: 10px;
  color: $TxtGray;
  font-size: 20px;
  padding-right: 10px;
}
.list_data .details.widthButton {
  width: 47%;
}
.safeco-app .list_data .details.widthButton.companyInfo {
  width: 47%;
}
.list_data .details.widthButton .name {
  font-size: 20px;
}
.list_data .details.widthButton .value {
  font-size: 15px;
}
.list_data .besideButton {
  width: 36%;
  float: right;
  padding-right: 15px;
}
.list_data .besideButton .btn {
  border-radius: 4px;
  border: 1px solid $lighter-blue;
  padding: 0 10px;
  line-height: 30px;
  font-size: 15px;
  float: right;
  margin: 6px 0 0 0;
  max-width: 96px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  color: $light-blue;
}
.list_data .besideButton .btn:hover {
  background: $blackSqueeze;
  color: $limedSpruce;
}
.list_data .details .left {
  width: 75%;
  float: left;
}
.list_data .details .right {
  width: 25%;
  float: left;
  text-align: right;
}
.list_data .details.wholeBtn .name {
  width: 85%;
  float: left;
  font-size: 20px;
  font-weight: 300;
  color: $hit_gray;
  padding: 8px 0;
}
.safeco-app .list_data .details.wholeBtn .name.instruction_txt {
  width: 100%;
}
.list_data .details.wholeBtn .arrow {
  width: 15%;
  height: 40px;
  float: left;
  background: url(./assets/Images/chevron_Gray.svg) no-repeat 90% 50%;
  background-size: 8px;
}
.list_data .arrow.requestArrow {
  width: 15%;
  height: 40px;
  float: right;
  margin-right: 25px;
  background: url(./assets/Images/chevron_Gray.svg) no-repeat 90% 50%;
  background-size: 8px;
}

.list_data.nothumb {
  margin: 15px 0 0 0;
  border-top: 1px solid $heather;
  border-bottom: 1px solid $heather;
  padding: 0 0 0 20px;
}
.list_data.nothumb .details {
  width: 94%;
  padding: 0;
}
.list_data.nothumb .row:after {
  left: 0;
}
.list_data.nothumb .link {
  color: $limedSpruce;
}

.pg_instruction {
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.97);
}
.pg_instruction .mainContent {
  background: $bgcolor;
  height: 100vh;
  padding-top: 70px;
}
.pg_instruction .mainContent.instructionsTxtArea {
  position: fixed;
  top: 0;
  height: calc(100vh - 70px);
}
.pg_instruction .mainContent.instructionsTxtArea .mainTextarea {
  min-height: 0;
  max-height: none;
  height: calc(100vh - 215px);
}
.pg_instruction .mainContent .mainTextarea {
  width: 100%;
  float: left;
  background: transparent;
  border: 0;
  min-height: 300px;
  max-height: 400px;
  line-height: 140%;
  resize: none;
}
.pg_instruction .bottom_fix {
  background: transparent;
}
.pg_instruction .bottom_fix.updateBtn {
  background: #f7f9fa;
}
.pg_instruction .bottom_fix .btnClose {
  background: $hit_gray;
  color: $white;
  border: 0;
}

.feedback {
  width: 100%;
  height: 100%;
  overflow: initial;
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.97);
}
.feedback .topbar {
  width: 100%;
  float: left;
  margin: 0 0 5px 0;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 105;
}
.feedback .topbar .title {
  width: 70%;
  float: left;
  padding: 15px;
  margin: 0 15%;
  font-size: 24px;
  font-weight: 600;
  color: $limedSpruce;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.feedback .topbar .close {
  position: absolute;
  top: 15px;
  right: 12px;
  opacity: 1;
}
.feedback .topbar .close a .fa {
  color: $greyC;
}
.feedback .topbar .close a .fa:hover {
  color: $grey;
}
.feedback .mainContent {
  padding: 70px 20px 70px 20px;
}
.feedback .rate_form {
  width: 100%;
  min-height: 1px;
  float: left;
  box-sizing: border-box;
  padding-bottom: 0;
}
.feedback .rate_form .title {
  width: 100%;
  margin: 0 0 10px 0;
  float: left;
  text-align: center;
  font-size: 20px;
  line-height: 140%;
  color: $black;
}
.feedback .rate_form .rate_faces {
  width: 100%;
  float: left;
  text-align: center;
  margin: 10px 0 20px 0;
}
.feedback .rate_form .rate_faces .rate_face {
  width: 41px;
  height: 41px;
  margin: 0 12px;
  display: inline-block;
  vertical-align: middle;
}
.feedback .rate_form .rate_faces .rate_face.face1 {
  background: url('./assets/Images/Rating1_Unselected.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face1.filled {
  background: url('./assets/Images/Rating1_Filled.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face1.selected {
  background: url('./assets/Images/Rating1_Selected.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face1_volvo {
  background: url('./assets/Images/Rating1_Unselected_Lgray.svg') no-repeat 50%
    50%;
}
.feedback .rate_form .rate_faces .rate_face.face1_volvo.filled {
  background: url('./assets/Images/Rating1_Filled_Lgray.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face1_volvo.selected {
  background: url('./assets/Images/Rating1_Selected_Lgray.svg') no-repeat 50%
    50%;
}

.feedback .rate_form .rate_faces .rate_face.face2 {
  background: url('./assets/Images/Rating2_Unselected.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face2.filled {
  background: url('./assets/Images/Rating2_Filled.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face2.selected {
  background: url('./assets/Images/Rating2_Selected.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face2_volvo {
  background: url('./assets/Images/Rating2_Unselected_Lgray.svg') no-repeat 50%
    50%;
}
.feedback .rate_form .rate_faces .rate_face.face2_volvo.filled {
  background: url('./assets/Images/Rating2_Filled_Lgray.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face2_volvo.selected {
  background: url('./assets/Images/Rating2_Selected_Lgray.svg') no-repeat 50%
    50%;
}

.feedback .rate_form .rate_faces .rate_face.face3 {
  background: url('./assets/Images/Rating3_Unselected.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face3.filled {
  background: url('./assets/Images/Rating3_Filled.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face3.selected {
  background: url('./assets/Images/Rating3_Selected.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face3_volvo {
  background: url('./assets/Images/Rating3_Unselected_Lgray.svg') no-repeat 50%
    50%;
}
.feedback .rate_form .rate_faces .rate_face.face3_volvo.filled {
  background: url('./assets/Images/Rating3_Filled_Lgray.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face3_volvo.selected {
  background: url('./assets/Images/Rating3_Selected_Lgray.svg') no-repeat 50%
    50%;
}

.feedback .rate_form .rate_faces .rate_face.face4 {
  background: url('./assets/Images/Rating4_Unselected.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face4.filled {
  background: url('./assets/Images/Rating4_Filled.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face4.selected {
  background: url('./assets/Images/Rating4_Selected.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face4_volvo {
  background: url('./assets/Images/Rating4_Unselected_Lgray.svg') no-repeat 50%
    50%;
}
.feedback .rate_form .rate_faces .rate_face.face4_volvo.filled {
  background: url('./assets/Images/Rating4_Filled_Lgray.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face4_volvo.selected {
  background: url('./assets/Images/Rating4_Selected_Lgray.svg') no-repeat 50%
    50%;
}

.feedback .rate_form .rate_faces .rate_face.face5 {
  background: url('./assets/Images/Rating5_Unselected.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face5.filled {
  background: url('./assets/Images/Rating5_Filled.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face5.selected {
  background: url('./assets/Images/Rating5_Selected.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face5_volvo {
  background: url('./assets/Images/Rating5_Unselected_Lgray.svg') no-repeat 50%
    50%;
}
.feedback .rate_form .rate_faces .rate_face.face5_volvo.filled {
  background: url('./assets/Images/Rating5_Filled_Lgray.svg') no-repeat 50% 50%;
}
.feedback .rate_form .rate_faces .rate_face.face5_volvo.selected {
  background: url('./assets/Images/Rating5_Selected_Lgray.svg') no-repeat 50%
    50%;
}

.feedback .red_items_box {
  width: 100%;
  float: left;
  margin: 0 0 20px 0;
}
.feedback .red_items_box * {
  box-sizing: border-box;
}
.feedback .red_items_box a.box {
  width: 100%;
  background: $white;
  float: left;
  text-decoration: none;
  border: 1px solid $red;
  border-radius: 3px;
  margin: 0 0 10px 0;
  padding: 12px 10px;
}
.feedback .red_items_box a.box .fa {
  color: $red;
  width: 20px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}
.feedback .red_items_box a.box .name {
  color: $red;
  font-size: 20px;
  font-weight: 600;
}
.feedback .red_items_box a.box.active {
  background: $red;
}
.feedback .red_items_box a.box.active .fa-minus:before {
  content: '\f00c';
  color: $white;
}
.feedback .red_items_box a.box.active .fa-plus:before {
  content: '\f00c';
  color: $white;
}
.feedback .red_items_box a.box.active .name {
  color: $white;
}

.feedback .green_items_box {
  width: 100%;
  float: left;
  margin: 0 0 20px 0;
}
.feedback .green_items_box * {
  box-sizing: border-box;
}
.feedback .green_items_box a.box {
  width: 100%;
  background: $white;
  float: left;
  text-decoration: none;
  border: 1px solid $greenShade1;
  border-radius: 3px;
  margin: 0 0 10px 0;
  padding: 12px 10px;
}
.feedback .green_items_box a.box .fa {
  color: $greenShade1;
  width: 20px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}
.feedback .green_items_box a.box .name {
  color: $greenShade1;
  font-size: 20px;
  font-weight: 600;
}
.feedback .green_items_box a.box.active {
  background: $greenShade1;
}
.feedback .green_items_box a.box.active .fa-plus:before {
  content: '\f00c';
  color: $white;
}
.feedback .green_items_box a.box.active .fa-minus:before {
  content: '\f00c';
  color: $white;
}
.feedback .green_items_box a.box.active .name {
  color: $white;
}
.feedback .bottom_fix .btn.dark {
  background: $limedSpruce;
  color: $white;
  text-transform: capitalize;
  line-height: 130%;
  font-weight: 400;
}

.feedback .feedback_pg {
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 !important;
  height: 100%;
  position: relative;
}
.feedback .feedback_pg .title {
  width: 100%;
  margin: 0 0 10px 0;
  float: left;
  text-align: center;
  font-size: 36px;
  color: $limedSpruce;
}
.feedback .feedback_pg .msg {
  width: 100%;
  line-height: 140%;
  margin: 0 0 10px 0;
  box-sizing: border-box;
  padding: 0;
  float: left;
  text-align: center;
  font-size: 20px;
  color: $limedSpruce;
}

.pg_instruction .bottom_fix .btnClose:hover {
  background: $limedSpruce !important;
  color: $white !important;
}

.payment_pg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $bgcolor;
}
.payment_pg header {
  border-bottom: 1px solid $heather;
  height: 55px;
  padding: 15px 0;
}
.payment_pg .main_content {
  margin-top: 55px;
}
.payment_pg .main_content.resetContent {
  height: calc(100vh - 120px);
  overflow: auto;
}
.safeco-app .payment_pg .main_content.resetContent {
  height: calc(100vh - 200px);
  overflow: auto;
}

.mssg {
  width: 100%;
  float: left;
  padding: 0 20px;
  margin: 0 0 10px 0;
  text-align: center;
  color: $limedSpruce;
  font-size: 18px;
  line-height: 140%;
  font-weight: 400;
}
.payment_pg .form_area .float_label label {
  font-weight: 300;
}
.payment_pg .float_label input:placeholder-shown + label {
  top: -2px;
}
.payment_pg .calculation {
  width: 100%;
  float: left;
  text-align: center;
  margin: 5px 0 20px 0;
}
.payment_pg .calculation .container {
  max-width: 320px;
}
.payment_pg .calculation p {
  font-size: 15px;
  color: $hit_gray;
  margin: 0 0 2px 0;
}
.payment_pg .calculation p strong {
  font-weight: 700;
}

.payment_pg .topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  padding: 20px 0;
  border-bottom: 1px solid $heather;
  height: 64px;
  z-index: 1;
}
.payment_pg .topbar .left {
  width: 20%;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
.payment_pg .topbar .right {
  width: 20%;
  text-align: right;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.payment_pg .topbar .lnk,
.topbar .close {
  font-size: 17px;
  color: $light-blue;
  margin: 0;
  line-height: 24px;
}
.payment_pg .topbar .lnk:hover,
.topbar .close:hover {
  color: $TxtGray;
  text-decoration: none;
}
.payment_pg .topbar .title {
  width: 60%;
  float: left;
  margin: 0 20%;
  font-size: 17px;
  line-height: 28px;
  color: $TxtGray;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.payment_pg .topbar .back_arrow {
  float: left;
}
.payment_pg .topbar .back_arrow img {
  float: left;
}

.form_area {
  width: 100%;
  float: left;
  background: $white;
  border-top: 1px solid $heather;
  border-bottom: 1px solid $heather;
}
.form_area .form_control {
  width: 100%;
  float: left;
  position: relative;
  padding: 18px 18px 15px 18px;
}
.form_area .form_control:after {
  width: 100%;
  height: 1px;
  content: '';
  background: $heather;
  position: absolute;
  bottom: 0;
  left: 0;
}
.form_area .form_control:before {
  width: 20px;
  height: 1px;
  content: '';
  background: $white;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.form_area .form_control:last-child:before {
  background: transparent;
}
.form_area .form_control:last-child:after {
  background: transparent;
}
.form_area .card_number {
  width: 100%;
  float: left;
}
.form_area .card_number .fa {
  font-size: 24px;
  color: $limedSpruce;
  position: absolute;
  left: 18px;
}
.form_area input[type='text'],
.form_area input[type='tel'],
.form_area input[type='password'],
.form_area input[type='email'] {
  width: 100%;
  float: left;
  font-size: 20px;
  border: 0;
}
.form_area .card_number .float_label {
  padding: 0 45px 0 35px;
}
.form_area .card_number .float_label label {
  padding: 0 45px 0 35px;
}
.form_area .card_number .float_label input[type='text']:focus + label,
.form_area .card_number .float_label input[type='tel']:focus + label,
.form_area .card_number .float_label input[type='password']:focus + label,
.form_area .card_number .float_label input[type='email']:focus + label {
  left: -35px;
}

.form_area .card_number .ic_camera {
  position: absolute;
  text-decoration: none;
  top: 11px;
  right: 18px;
  width: 40px;
  height: 40px;
  background: $blackSqueeze;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}
.form_area .card_number .ic_camera .fa {
  color: $heather;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  left: 0;
  top: 0;
}
.form_area .card_number .ic_green_tick {
  position: absolute;
  text-decoration: none;
  top: 20px;
  right: 18px;
  width: 22px;
  height: 22px;
  background: $green;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
}
.form_area .card_number .ic_green_tick .fa {
  color: $white;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  font-size: 16px;
}
.form_area .half {
  width: 50%;
  float: left;
}
.form_area .expDate {
  width: 100%;
  float: left;
  position: relative;
}
.form_area .expDate .ic_help {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0px;
  right: 15px;
  background: $blackSqueeze;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
}
.form_area .expDate .ic_help .fa {
  color: $heather;
}
.form_area .expDate .ic_green_tick {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0px;
  right: 15px;
  background: $greenShade1;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
}
.form_area .expDate .ic_green_tick .fa {
  color: $white;
}
.form_area .zipcode {
  width: 100%;
  float: left;
  position: relative;
}
.form_area .zipcode .ic_help {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0px;
  right: 0;
  background: $blackSqueeze;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
}
.form_area .zipcode .ic_help .fa {
  color: $heather;
}
.form_area .zipcode .ic_green_tick {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0px;
  right: 0;
  background: $greenShade1;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
}
.form_area .zipcode .ic_green_tick .fa {
  color: $white;
}
.form_area .cvv {
  width: 100%;
  float: left;
  position: relative;
}
.form_area .cvv .ic_help {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0px;
  right: 0;
  background: $blackSqueeze;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
}
.form_area .cvv .ic_help .fa {
  color: $heather;
}
.form_area .cvv .ic_green_tick {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0px;
  right: 0;
  background: $greenShade1;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
}
.form_area .cvv .ic_green_tick .fa {
  color: $white;
}

.form_area .country_dropdown {
  width: 100%;
  float: left;
}
.form_area .country_dropdown .name {
  width: 100%;
  height: 24px;
  color: $limedSpruce;
  position: relative;
  float: left;
  padding: 0 40px;
  font-size: 20px;
  line-height: 24px;
}
.form_area .country_dropdown .name .flag {
  max-width: 32px;
  max-height: 24px;
  float: left;
  position: absolute;
  top: 3px;
  left: 0;
}
.form_area .country_dropdown .name .flag img {
  max-width: 32px;
  max-height: 22px;
  float: left;
}
.form_area .country_dropdown .name .arrow {
  width: 10px;
  height: 12px;
  position: absolute;
  top: 5px;
  right: 0;
}
.form_area .country_dropdown .name .arrow img {
  width: 100%;
  max-height: 100%;
  float: left;
}

.float_label {
  width: 100%;
  float: left;
  position: relative;
}
.float_label input + label,
.float_label input[type='text']:focus + label,
.float_label input[type='tel']:focus + label,
.float_label input[type='password']:focus + label,
.float_label input[type='email']:focus + label {
  width: 100%;
  transition: 0.6s all ease;
  color: $heather;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  top: -14px;
  position: absolute;
  left: 0;
}
.float_label input:placeholder-shown + label {
  width: 100%;
  float: left;
  color: $heather;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  top: -5px;
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 138%;
  position: absolute;
  left: 0;
}
.cancel-out {
  position: fixed;
  bottom: 0px;
  width: 100%;
  transition: 0.5s;
  height: 0px;
  z-index: -2;
}
.cancel-in {
  height: 180px;
  background: $white;
  width: 100%;
  min-height: 50px; /* max-height: 520px; */
  padding: 15px 0;
  box-shadow: 0px -6px 13px -4px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  opacity: 1;
  overflow: scroll;
}
.cancel-in .job-row-container {
  padding: 15px 15px 0px 15px;
}
.showScroll::-webkit-scrollbar-thumb {
  width: 5px;
  background: $greyA776;
  border-radius: 3px;
}
.showScroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}
.height190 {
  height: 190px;
}
.height238 {
  height: 238px;
}
.scroll {
  overflow-y: auto !important;
}
.blur * {
  filter: blur(2px);
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 15;
  background: $black40opac;
}

.bottom_drawer.cancelJob {
  z-index: 100;
  padding: 15px 0 70px 0;
}
.bottom_drawer.cancelJob .list_data {
  padding: 0 0 0 20px;
}
.bottom_drawer.cancelJob .list_data .row:after {
  left: 0;
}
.bottom_drawer.cancelJob .list_data .row .details {
  width: 100%;
  position: relative;
}
.bottom_drawer.cancelJob .list_data .row .details .name {
  width: 100%;
  padding-right: 50px;
}
.bottom_drawer.cancelJob .list_data .row .details .tick {
  display: none;
  position: absolute;
  top: 8px;
  right: 20px;
  width: 13px;
  height: 10px;
  background: url(./assets/Images/Check-blue.svg);
}
.bottom_drawer.cancelJob .list_data .row .details .tick.show {
  display: block;
}
.bottom_drawer.cancelJob .list_data .row .details .inputarea {
  width: 100%;
  padding-right: 20px;
}
.bottom_drawer.cancelJob .list_data .row .details textarea {
  width: 100%;
  border: 1px solid $greyA776 !important;
  padding: 10px;
}
.bottom_drawer.cancelJob .list_data .row .details textarea::placeholder {
  color: $greyA776;
}
.bottom_drawer.cancelJob .bottom_fix .btnClose {
  font-weight: 400 !important;
  text-transform: capitalize;
  color: $black;
  border-color: $greyA776 !important;
}
.bottom_drawer.cancelJob .bottom_fix .btnClose.active,
.bottom_drawer.cancelJob .bottom_fix .btnClose:hover {
  background: $limedSpruce !important;
  color: $white !important;
}
.bottom_drawer.cancelJob .bottom_fix .btnClose.active:hover {
  background: $lighter-blue !important;
  color: $black !important;
  border-color: $lighter-blue !important;
}
.bottom_drawer.cancelJob .bottom_fix .btnClose.no {
  border-color: $lighter-blue !important;
  color: $light-blue;
}
.bottom_drawer.cancelJob .bottom_fix .btnClose.no:hover {
  border-color: $lighter-blue !important;
  color: $white !important;
  background: $light-blue !important;
}

.smily_faces {
  width: 100%;
  float: left;
  text-align: center;
  margin: 10px 0 30px 0;
}
.smily_faces .smily_face {
  width: 40px;
  height: 40px;
  background: url('./assets/Images/Rating5_Filled.svg') no-repeat 50% 50%;
  margin: 0 8px;
  display: inline-block;
  vertical-align: middle;
}
.smily_faces .smily_face_volvo {
  width: 40px;
  height: 40px;
  background: url('./assets/Images/Rating5_Filled_Lgray.svg') no-repeat 50% 50%;
  margin: 0 8px;
  display: inline-block;
  vertical-align: middle;
}
.smily_faces .smily_face.selected {
  width: 70px;
  height: 70px;
  background: url('./assets/Images/Rating5_Done.svg') no-repeat 50% 50%;
}
.smily_faces .smily_face_volvo.volvo_selected {
  width: 70px;
  height: 70px;
  background: url('./assets/Images/Rating5_volvo_Done.svg') no-repeat 50% 50%;
}

.swal2-show * {
  font-family: $FontSans;
}
.swal2-show {
  min-height: 1px !important;
  border-radius: 25px;
  width: 100%;
  max-width: 315px;
  padding: 0 !important;
}
.swal2-show .swal2-title {
  padding: 20px 15px 0 15px;
  margin: 0 0 10px 0;
  color: $black;
  width: 100%;
  float: left;
  font-size: 22px;
  font-weight: 700;
  font-family: $FontSans;
  line-height: 130%;
}
.swal2-show .swal2-content {
  width: 100%;
  float: left;
  padding: 0 15px 20px 15px;
  color: $black;
  font-size: 18px;
  font-weight: 400;
  font-family: $FontSans;
  line-height: 130%;
}
.swal2-show .swal2-buttonswrapper {
  border-top: 1px solid #eeeeee;
  width: 100%;
  float: left;
  margin: 0;
}
.swal2-modal .swal2-styled {
  margin: 5px;
  background: transparent !important;
  border: 0 !important;
  box-shadow: 0 0 0 !important;
  color: $azureBlue;
  font-size: 22px;
}
.swal2-confirm.swal2-styled {
  float: right;
}

.bt {
  border-top: 1px solid $heather;
}
.bb {
  border-bottom: 1px solid $heather;
}
.funkyradio div {
  clear: both;
  overflow: hidden;
}
.funkyradio label {
  width: 100%;
  font-size: 20px;
}
.funkyradio input[type='radio']:empty,
.funkyradio input[type='checkbox']:empty {
  display: none;
}
.funkyradio input[type='radio']:empty ~ label,
.funkyradio input[type='checkbox']:empty ~ label {
  position: relative;
  line-height: 2.5em;
  margin: 0;
  padding: 3px 0;
  border-bottom: 1px solid $mid-grey;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.funkyradio input[type='radio']:empty ~ label.last,
.funkyradio input[type='checkbox']:empty ~ label.last {
  border-bottom: 0;
}
.funkyradio input[type='radio']:empty ~ label:before,
.funkyradio input[type='checkbox']:empty ~ label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  content: '';
  width: 55px;
  background: none;
}
.funkyradio input[type='radio']:hover:not(:checked) ~ label,
.funkyradio input[type='checkbox']:hover:not(:checked) ~ label {
}
.funkyradio input[type='radio']:hover:not(:checked) ~ label:before,
.funkyradio input[type='checkbox']:hover:not(:checked) ~ label:before {
  background: url(./assets/Images/Check-blue.svg) no-repeat center;
}
.funkyradio input[type='radio']:checked ~ label,
.funkyradio input[type='checkbox']:checked ~ label {
}
.funkyradio input[type='radio']:checked ~ label:before,
.funkyradio input[type='checkbox']:checked ~ label:before {
  background: url(./assets/Images/Check-blue.svg) no-repeat center;
}
.funkyradio input[type='radio']:focus ~ label:before,
.funkyradio input[type='checkbox']:focus ~ label:before {
  /*box-shadow: 0 0 0 3px $grey-moretxt;*/
}
.funkyradio-default input[type='radio']:checked ~ label:before,
.funkyradio-default input[type='checkbox']:checked ~ label:before {
  background-color: transparent;
}
.funkyradio textarea {
  padding: 10px;
  margin: 0 !important;
}
.funkyradio textarea::placeholder {
  color: $heather;
}

.listing .list_data .details {
  width: 82%;
  position: relative;
}
.listing .list_data .details .name {
  padding: 8px 0;
}
.listing .list_data .details .tick {
  width: 13px;
  height: 11px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: url(./assets/Images/Check-blue.svg);
}
.listing .list_data .details.wholeBtn .arrow {
  width: 15%;
  height: 40px;
  float: left;
  background: url(./assets/Images/chevron_Gray.svg) no-repeat 100% 50%;
  background-size: 8px;
}
.listing .list_data .row.last:after {
  height: 0;
}

.pac-container {
  top: 120px !important;
  width: 100% !important;
  font-family: $FontSans;
  max-height: 180px !important;
  overflow-y: auto !important;
  left: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #d6e1e5;
}
.pac-container .pac-item {
  padding: 10px 20px !important;
  border-top: 0 !important;
  position: relative;
}
.pac-container .pac-item:after {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 95%;
  height: 1px;
  content: '';
  background: $heather;
}
.pac-container .pac-item:last-child:after {
  height: 0;
}
.pac-container .pac-item .pac-icon {
  display: none !important;
}
.pac-container .pac-item .pac-item-query {
  width: 100%;
  float: left;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: $limedSpruce;
  font-weight: 700 !important;
}
.pac-container .pac-item span:nth-child(3) {
  width: 100%;
  float: left;
  font-size: 15px;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #364347;
}
.pac-container .pac-matched {
  font-weight: 700 !important;
}

.policyDetail .grey-button {
  width: 100%;
  margin: 0 0 10px 0;
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: 20px;
  text-transform: uppercase;
  background: $limedSpruce;
  padding: 15px;
  color: $white;
  font-weight: normal;
  border-radius: 4px;
}
.policyDetail .grey-button:hover {
  background: $lighter-blue;
  color: $black;
}
.policyDetail .grey-button.disabled,
.policyDetail .grey-button.disabled:hover {
  background: $dark-grey;
  color: $white;
}
.policyDetail .bold-title {
  color: $limedSpruce;
  font-weight: 700;
  display: inline-block;
  vertical-align: top;
}
.policyDetail .semibold {
  color: $limedSpruce;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
}
.policyDetail .row {
  margin: 0 0 15px 0;
}
.policyDetail p {
  margin: 0;
  line-height: 1.3;
  color: $limedSpruce;
}
.home-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 10px 10px 0 10px;
  z-index: 15;
  background: $white;
}
.policyDetailContent {
  width: 100%;
  float: left;
  padding: 0;
  margin: 45px 0 0 0;
  position: relative;
  z-index: 10;
}
.dropdown {
  width: 100%;
  float: left;
  border: 1px solid $lighter-blue;
  padding: 0;
  margin: 0 0 10px 0;
  border-radius: 4px;
  position: relative;
}
.dropdown select {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  border-radius: 4px;
  padding: 12px 25px 12px 10px;
}
.dropdown:after {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  content: '';
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid $heather;
}

.bottom_fix .grey-button {
  width: 100%;
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
  margin: 0;
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: 20px;
  text-transform: uppercase;
  background: $limedSpruce;
  padding: 15px;
  color: $white;
  font-weight: normal;
  border-radius: 4px;
}
// .bottom_fix .grey-button:hover { background:$lighter-blue; color:$black; }

/*Textbox animation*/
.text-area input,
.text-area span,
.text-area label,
.text-area textarea {
  font-family: $FontSans;
  display: block;
  margin: 10px;
  padding: 5px;
  border: none;
  font-size: 22px;
}
.text-area textarea:focus,
.text-area input:focus {
  outline: none;
}
/* Question */
.text-area input.question,
.text-area textarea.question {
  font-size: 17px;
  font-weight: 400;
  border-radius: 2px;
  margin: 20px 0 7px 0;
  border: none;
  width: 80%; /*background: rgba(0, 0, 0, 0);*/
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  overflow-x: hidden;
  background-color: $white;
  color: $limedSpruce; /* Hack to make "rows" attribute apply in Firefox. */
}
/* Underline and Placeholder */
.text-area input.question + label,
.text-area textarea.question + label {
  display: block;
  position: relative;
  //white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 100%; /*border-top: 1px solid red;*/
  -webkit-transition: width 0.4s ease;
  transition: width 0.4s ease;
  height: 0px;
}
.text-area input.question:focus + label,
.text-area textarea.question:focus + label {
  width: 100%;
}
.text-area input.question:focus,
.text-area input.question:valid {
  padding-top: 5px; /*padding-top: 35px;*/
}
.text-area textarea.question:valid,
.text-area textarea.question:focus {
  margin-top: 35px;
}
.text-area input.question:focus + label > span,
.text-area input.question:valid + label > span {
  top: -56px;
  font-size: 12px;
  color: $mid-grey;
}
.text-area textarea.question:focus + label > span,
.text-area textarea.question:valid + label > span {
  top: -150px;
  font-size: 22px; /* color: $gray3; */
}
.text-area input.question:valid + label,
.text-area textarea.question:valid + label {
  /* border-color: green;*/
}
.text-area input.question:invalid,
.text-area textarea.question:invalid {
  box-shadow: none;
}
.text-area input.question + label > span,
.text-area textarea.question + label > span {
  font-weight: 400;
  margin: 0;
  position: absolute;
  color: $mid-grey;
  font-size: 17px;
  top: -45px;
  left: 0px;
  z-index: 0;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  width: 100%;
  overflow: hidden;
}
.text-area input.question[readonly] + label span {
  top: -54px;
  font-size: 12px;
  color: $mid-grey;
}
.text-area input[type='submit'] {
  -webkit-transition: opacity 0.2s ease, background 0.2s ease;
  transition: opacity 0.2s ease, background 0.2s ease;
  display: block;
  opacity: 0;
  margin: 10px 0 0 0;
  padding: 10px;
  cursor: pointer;
}
.text-area input[type='submit']:hover {
  /*background: $grayE;*/
}
.text-area input[type='submit']:active {
  /* background: $grey-moretxt; */
}
.text-area input.question:valid ~ input[type='submit'],
.text-area textarea.question:valid ~ input[type='submit'] {
  -webkit-animation: appear 1s forwards;
  animation: appear 1s forwards;
}
.text-area input.question:invalid ~ input[type='submit'],
.text-area textarea.question:invalid ~ input[type='submit'] {
  display: none;
}
.text-area select {
  width: 100%;
  float: left;
  padding: 18px 15px;
  border: 0;
  border-bottom: 1px solid $mid-grey;
}

.white-txtbg {
  background: $white;
  border-bottom: 1px solid $mid-grey;
  padding: 0px 0px 0 15px;
  float: left;
  width: 100%;
  position: relative;
}

.grybdr-top-btm {
  border-bottom: 1px solid $mid-grey;
  border-top: 1px solid $mid-grey;
}
.grybdr-top {
  border-bottom: 1px solid $mid-grey;
  border-top: 1px solid $mid-grey;
}

.policy-txt {
  font-size: 18px;
  line-height: 22px;
  color: $hit_gray;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 20px 15px;
  margin: 0;
}
.policy-txt.bmw_policy_txt {
  color: #687479;
  line-height: 20px;
}
.policy-txt.bmw_policy_txt .call_us_link {
  font-size: 18px !important;
  font-weight: normal !important;
  cursor: pointer;
}
.policy-txt.bmw_policy_new {
  color: #364347;
  padding: 25px 5px;
}
.policy-txt a {
  color: $light-blue;
  font-weight: bold;
}
.policy-txt.bmw_policy_new a {
  color: #00c2f7;
  font-weight: 600;
}
.policy-txt a:hover,
.policy-txt a:focus,
.policy-txt a:active {
  text-decoration: none;
  color: $light-blue;
}
.policy-txt.small {
  font-size: 15px;
  line-height: 18px;
}

.sep-line {
  width: 100%;
  height: 1px;
  background: $mid-grey;
  padding: 0 0 0 20px;
  position: relative;
}

.policyDetailSearchPG {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: $bgcolor;
}
.policyDetailSearchPG .header {
  border-bottom: 1px solid $mid-grey;
}
.policyDetailSearchPG .header .title {
  color: $limedSpruce;
}
.policyDetailSearchPG .text-area input.question:focus + label > span,
.policyDetailSearchPG .text-area input.question:valid + label > span {
  top: -56px;
  font-size: 12px;
  color: $mid-grey;
  text-transform: uppercase;
}
.policyDetailSearchPG .bottom_fix {
  background: $bgcolor;
  padding: 15px;
}
.policyDetailSearchPG .bottom_fix.bottom_fix_bmw {
  padding: 20px;
}
.policyDetailSearchPG .bottom_fix.bottom_fix_bmw .btn {
  text-transform: capitalize;
  padding: 10px 15px;
}
.policyDetailSearchPG .bottom_fix.bottom_fix_bmw .btn.disabled {
  background-color: #02baf2;
  opacity: 0.3;
}
.policyDetailSearchPG .bottom_fix .btn {
  font-weight: 400;
}
.policyDetailSearchPG .bottom_fix .policy-txt {
  padding: 0;
  margin: 0 0 15px 0;
}

.newforDemoJune2018 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: $white;
}
.newforDemoJune2018 .header {
  z-index: 15;
  border-bottom: 1px solid $mid-grey;
}
.newforDemoJune2018 .header .title {
  color: $limedSpruce;
}
.newforDemoJune2018 .header .lnk {
  color: $light-blue;
}
.newforDemoJune2018 .policyDetailContent {
  margin: 0;
}
.newforDemoJune2018 .home-footer .btn {
  width: 100%;
  border-radius: 4px;
  margin: 0 0 10px 0;
  padding: 12px 15px;
  background: $light-blue;
  box-sizing: border-box;
  text-decoration: none;
  color: $white;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.newforDemoJune2018 .home-footer .btn.disabled {
  background: $frenchPass;
}
.newforDemoJune2018 .home-footer .btn:hover {
  background: $blackSqueeze;
  color: $limedSpruce;
}
.newforDemoJune2018 .home-footer .btn.dark {
  background: $limedSpruce;
  color: $white;
}
.newforDemoJune2018 .home-footer .btn.dark:hover {
  background: $blackSqueeze;
  color: $limedSpruce;
}
.newforDemoJune2018 .home-footer .btn.black {
  background: $limedSpruce;
  color: $white;
}
.newforDemoJune2018 .home-footer .btn.black:hover {
  background: $blackSqueeze;
  color: $limedSpruce;
}
.newforDemoJune2018 .home-footer .btnClose {
  width: 100%;
  border-radius: 4px;
  padding: 14px 13px;
  background: $white;
  box-sizing: border-box;
  text-decoration: none;
  color: $light-blue;
  display: inline-block;
  font-size: 20px;
  font-weight: 600 !important;
  text-transform: uppercase;
  border-color: $lighter-blue;
  border-style: solid;
  border-width: 1px;
}
.newforDemoJune2018 .home-footer .btnClose:hover {
  background: $blackSqueeze !important;
  color: $limedSpruce !important;
}
.newforDemoJune2018 .home-footer .btnClose.half {
  width: 49%;
}
.newforDemoJune2018 .home-footer .btnClose.half.left {
  margin-right: 1%;
}
.newforDemoJune2018 .home-footer .btnClose.half.right {
  margin-left: 1%;
}
.newforDemoJune2018 .home-footer.btn-disable {
  background: none !important;
}
.newforDemoJune2018 .mainContent {
  padding: 55px 12px 70px 12px;
}
.newforDemoJune2018 .policy-txt {
  padding: 15px;
}

.splashScreen {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: $light-blue;
  z-index: 1;
}
.splashScreen .bgobject {
  width: 100%;
  height: 100%;
  background: url(./assets/Images/ic_urgently_icon.svg) no-repeat center;
  background-size: auto 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.splashScreen .main_logo {
  width: 100%;
  max-width: 240px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.splashScreen .main_logo img {
  width: 100%;
}
.splashScreen .bottom_fix {
  background: transparent;
}
.splashScreen .btn.white {
  border: 1px solid $white;
  background: transparent;
}
.splashScreen .btn.white:hover {
  background: $white;
  color: $limedSpruce;
}

@media (max-width: 360px) {
  .feedback .rate_form .rate_faces .rate_face {
    padding: 0 7px;
  }
  .feedback .rate_form .rate_faces .rate_face {
    margin: 0 7px;
  }
  .smily_faces .smily_face {
    margin: 0 5px;
  }
}

// Volvo Rebranding start
.volvo-app *:not(.fa) {
  font-family: 'Volvo Novum';
}
.volvo-app a:hover,
.volvo-app a:focus,
.volvo-app a:active:focus {
  text-decoration: none !important;
}
.volvo-app .progress_box .title {
  font-weight: 300;
  font-size: 17px;
  color: rgba(51, 51, 51, 1);
}
.volvo-app .progress_box .title.volvoTitle {
  font-size: 20px;
}
.volvo-app .progress_box {
  margin-bottom: 15px;
}
.volvo-app .progress_box .subtitle {
  font-size: 15px;
  font-weight: 300;
  color: rgba(149, 149, 149, 1);
  font-style: normal;
}
.volvo-app .chatBox .receiver .revise_btn {
  font-weight: 500;
}
.volvo-app .list_data .details .name {
  font-weight: 300;
  color: rgba(51, 51, 51, 1);
  font-size: 17px;
}
.volvo-app .list_data .details .value {
  font-weight: 300;
  color: rgba(149, 149, 149, 1);
  font-size: 15px;
}
.volvo-app .bottom_fix .btn.dark,
.volvo-app .bottom_fix .btnClose,
.volvo-app .bottom_drawer.cancelJob .bottom_fix .btnClose.no,
.volvo-app .bottom_fix .grey-button,
.volvo-app .bottom_fix .btn.black {
  background: transparent;
  color: #075ba5;
  border: 1px solid #075ba5;
  font-weight: 300 !important;
  border-radius: 0;
  font-size: 18px;
  padding: 10px 15px;
  text-transform: none;
}
.volvo-app .bottom_fix .grey-button.volvoShareBtn {
  max-width: 223px;
  padding: 12px 15px;
  font-size: 24px;
  margin-bottom: 0;
}
.volvo-app .bottom_fix .grey-button.volvoShareBtn:hover {
  background: transparent !important;
  color: #075ba5 !important;
  border: 1px solid #075ba5 !important;
}
.volvo-app .bottom_fix .grey-button.volvoShareBtn.active {
  background: #075ba5 !important;
  color: #fff !important;
  border: 1px solid #075ba5 !important;
}
.volvo-app .bottom_fix .btn.dark:hover,
.volvo-app .bottom_fix .btnClose:hover,
.volvo-app .bottom_drawer.cancelJob .bottom_fix .btnClose.no:hover,
.volvo-app .bottom_fix .grey-button:hover,
.volvo-app .bottom_fix .btn.black:hover {
  background: #075ba5 !important;
  color: #fff !important;
}
.volvo-app .progress_bar .progress_meter {
  background: #22537a;
  height: 3px;
  border-radius: 0;
}
.volvo-app .swal2-modal .swal2-styled {
  font-weight: normal;
  font-size: 20px;
}
.volvo-app .bottom_drawer .drawer_base .top_link a {
  font-size: 15px;
  font-weight: normal;
  color: #22537a;
}
.volvo-app .job_link {
  font-size: 15px;
  color: #333333;
  position: absolute;
  top: 24px;
  left: 15px;
}
.volvo-app .btn_center {
  max-width: 50%;
  min-width: 224px;
}
.volvo-app .bottom_btn_link_row {
  margin-bottom: 15px;
  margin-top: 46px;
}
.volvo-app .bottom_btn_link_row .bottom_btn_link {
  color: #22537a;
  display: inline-block;
  font-size: 17px;
}
.volvo-app .volvo_job_details_view {
  padding-bottom: 90px;
}
.volvo-app .volvo_job_details_view.mb15 {
  margin-bottom: 0 !important;
}
.volvo-app .volvo_job_details_view.height190 {
  height: 340px;
}
.volvo-app .volvo_job_details_view.volvo_share_view {
  height: auto;
  padding-bottom: 20px;
}
.volvo-app .list_data .details.widthButton {
  width: 80%;
}
.volvo-app .feedback .red_items_box a.box .name,
.volvo-app .feedback .red_items_box a.box .fa,
.volvo-app .feedback .green_items_box a.box .fa,
.volvo-app .feedback .green_items_box a.box .name {
  font-weight: normal;
}
.volvo-app .getHelp .topbar .title,
.volvo-app .feedback .topbar .title {
  font-weight: normal;
}
.volvo-app .bottom_fix .btnClose.btn_call_driver {
  padding: 12px 15px;
  font-size: 24px;
}
.volvo-app .bottom_fix .btnClose.btn_call_driver,
.volvo-app .bottom_fix .btnClose.btn_call_customer,
.volvo-app .bottom_fix .grey-button {
  max-width: 225px;
  margin: 0 auto 10px;
  display: block;
}
.volvo-app .bottom_fix .btnClose.btn_call_customer {
  color: #b20f00;
  border-color: rgba(149, 149, 149, 0.5);
  font-size: 20px;
  padding: 9px 15px;
}
.volvo-app .bottom_fix .btnClose.btn_call_customer:hover,
.volvo-app .bottom_fix .btnClose.btn_call_customer:focus,
.volvo-app .bottom_fix .btnClose.btn_call_customer:active:focus {
  background: #b20f00 !important;
  border-color: #b20f00 !important;
  color: #fff !important;
}

.volvo-app .volvo-drawer.bottom_drawer,
.volvo-app .volvo-drawers {
  // padding-bottom: 0 !important;
  padding-bottom: 120px !important;
}
.volvo-app .progress-bar {
  background: rgba(34, 83, 122, 1);
  border-radius: 0;
}
.volvo-app .list_data .row:after {
  background: rgba(211, 211, 211, 0.5);
}

.volvo-app .funkyradio label {
  font-size: 17px;
  font-weight: 300;
}
.volvo-app .bottom_drawer.cancelJob .bottom_fix .btnClose.active,
.volvo-app .bottom_drawer.cancelJob .bottom_fix .btnClose:hover {
  border-color: #075ba5 !important;
  background: #075ba5 !important;
}
.volvo-app .feedback .feedback_pg .msg {
  font-size: 15px;
  color: #959595;
  font-weight: 300;
}
.volvo-app .feedback .feedback_pg .title {
  font-size: 17px;
  color: #333;
  font-weight: 300;
}

.volvo-app .chatBox .receiver .revise_btn {
  font-weight: 500;
  color: rgba(34, 83, 122, 1);
}

.volvo-app .chatBox .sender .chats .msg {
  border: 1px solid rgba(143, 179, 204, 1);
  border-radius: 8px;
  color: #333;
}
.volvo-app .chatBox .receiver .chats a.msg {
  background: rgba(34, 83, 122, 1);
  color: #fff;
  font-family: 'Volvo Novum SemiLight';
  font-weight: 300;
}
.volvo-app .progress,
.volvo-app .progress_bar {
  background: rgba(216, 216, 216, 0.3);
  width: 79%;
  margin-left: auto;
  margin-right: auto;
}
.volvo-app .feedback .topbar .title {
  font-size: 17px;
  color: #333;
  padding: 20px 15px 15px;
}
.volvo-app .feedback .rate_form .title {
  font-size: 15px;
  color: #333;
}
.volvo-app .feedback .red_items_box a.box {
  border-color: #b20f00;
}
.volvo-app .feedback .red_items_box a.box.active {
  background: #b20f00;
}
.volvo-app .feedback .red_items_box a.box .fa,
.volvo-app .feedback .red_items_box a.box .name {
  color: #b20f00;
  font-size: 17px;
}
.volvo-app .feedback .red_items_box a.box.active .fa,
.volvo-app .feedback .red_items_box a.box.active .name {
  color: #fff;
}

.volvo-app .feedback .green_items_box a.box {
  border-color: #22537a;
}
.volvo-app .feedback .green_items_box a.box.active {
  background: #22537a;
}
.volvo-app .feedback .green_items_box a.box .fa,
.volvo-app .feedback .green_items_box a.box .name {
  color: #22537a;
  font-size: 17px;
}
.volvo-app .feedback .green_items_box a.box.active .fa,
.volvo-app .feedback .green_items_box a.box.active .name {
  color: #fff;
}
.volvo-app .feedback .red_items_box a.box .fa,
.volvo-app .feedback .green_items_box a.box .fa {
  margin-right: 0;
  font-size: 15px;
}
.volvo-app .volvo_job_details_view .bottom_fix {
  position: relative;
}
@media only screen and (min-width: 300px) and (max-width: 767px) {
  .volvo-app .volvo_job_details_view.height190 {
    height: 140px !important;
    padding-bottom: 10px !important;
  }
}

.volvo-app .volvo_job_details_view.height190 {
  height: 170px;
  padding-bottom: 40px;
}

@media only screen and (max-width: 360px) {
  .volvo-app .volvo_job_details_view {
    padding-bottom: 84px;
  }
  .volvo-app .volvo-drawer.bottom_drawer,
  .volvo-app .volvo-drawers {
    padding-bottom: 120px !important;
  }
  .volvo-app .bottom_btn_link_row {
    margin-top: 25px;
    margin-bottom: 5px;
  }
}
// Volvo Rebranding end

// Safeco CSS Start //

.safeco-app .safecoPolicyDetail .header .title {
  color: rgba(54, 67, 71, 0.8);
  font-size: 20px;
}
.safeco-app .safecoPolicyDetail .policy-txt {
  color: #687479;
  padding-top: 12px;
  padding-bottom: 10px;
}
.safeco-app .safecoPolicyDetail .policy-txt a {
  color: #02baf2;
  font-weight: 600;
  font-size: 15px;
}
.safeco-app .safecoPolicyDetail .bottom_fix {
  padding: 20px;
}
.safeco-app .safecoPolicyDetail .bottom_fix .btn {
  text-transform: none;
  padding: 10px 15px;
}
.safeco-app .safecoPolicyDetail .text-area input.question + label > span,
.safeco-app .safecoPolicyDetail .text-area textarea.question + label > span {
  font-size: 18px;
}
.safeco-app .safecoPolicyDetail .text-area input.question:focus + label > span,
.safeco-app .safecoPolicyDetail .text-area input.question:valid + label > span {
  font-size: 13px;
  width: auto;
}
.safeco-app .newforDemoJune2018 .header .title,
.rsa-app .newforDemoJune2018.policyDetailScreen .header .title {
  color: rgba(54, 67, 71, 0.8);
  font-size: 20px;
}
.safeco-app .safecoPolicyFound,
.rsa-app .policyDetailScreen .safecoPolicyFound {
  background: #f7f9fa;
}
.safeco-app .safecoPolicyFound .policy-txt,
.rsa-app .policyDetailScreen .safecoPolicyFound .policy-txt {
  color: #687479;
  padding-top: 12px;
  padding-bottom: 10px;
}
.safeco-app .safecoPolicyFound,
.rsa-app .policyDetailScreen .safecoPolicyFound {
  padding: 55px 20px 90px 20px;
}
.safeco-app .newforDemoJune2018 .home-footer .btn,
.rsa-app .policyDetailScreen .home-footer .btn {
  text-transform: none;
  padding: 10px 15px;
}
.safeco-app .home-footer,
.rsa-app .policyDetailScreen .home-footer {
  padding: 20px;
  background: #f7f9fa;
}
.safeco-app .newforDemoJune2018 .home-footer .btn,
.rsa-app .policyDetailScreen .home-footer .btn {
  margin-bottom: 0;
}
.rsa-app .policyDetailScreen .safecoPolicyFound .bold-title,
.rsa-app .policyDetailScreen .safecoPolicyFound p,
.rsa-app .policyDetailScreen .safecoPolicyFound .semibold {
  font-size: 14px;
  color: #5e696d;
  font-weight: 400;
}
.safeco-app .safecoPolicyFound .bold-title,
.rsa-app .policyDetailScreen .safecoPolicyFound .bold-title {
  font-weight: 700;
}

.safeco-app .safecoPolicyFound .dropdown,
.rsa-app .policyDetailScreen .safecoPolicyFound .dropdown {
  margin: 3px 0 0 0;
}
.safeco-app .safecoPolicyFound .dropdown select,
.rsa-app .safecoPolicyFound .dropdown select {
  background: #ffffff;
}
.safeco-app .newforDemoJune2018,
.rsa-app .policyDetailScreen {
  background: #f7f9fa;
}
.safeco-app .safecoPayment .bottom_fix {
  background: #f7f9fa;
}
.safeco-app .safecoPayment .bottom_fix .btn {
  text-transform: none;
  padding: 10px 15px;
}
.safeco-app .safecoPayment .form_area .card_number {
  position: relative;
}
.safeco-app .safecoPayment .form_area .card_number .ic_help.ic_cam {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: #e1eef3;
  border-radius: 50%;
  text-align: center;
  line-height: 29px;
}
.safeco-app .safecoPayment .form_area .card_number .ic_help.ic_cam .fa {
  color: $heather;
  position: absolute;
  top: 50%;
  font-size: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.safeco-app .safecoPayment .form_area .card_number .fa {
  left: 0;
}
.safeco-app .safecoPayment .form_area .card_number .ic_green_tick {
  top: 0;
  right: 0;
}
.safeco-app .bottom_drawer .list_data .row .edit_details {
  float: right;
  padding: 10px 30px 10px 5px;
  color: skyblue;
  font-size: 13px;
  font-weight: 600;
  color: $light-blue;
  text-transform: uppercase;
  position: relative;
  top: 5px;
  cursor: pointer;
}
.safeco-app .list_data .details {
  float: left;
  width: 100%;
  max-width: 60%;
  padding: 0;
}
.safeco-app .bottom_fix.safeco_btn .btn {
  text-transform: none;
}
// Safeco CSS End //
.volvo-app .btn.dark.requestHelpBtn {  
  padding: 7px 15px;
  font-size: 24px;
  display: inline-block;
  width: auto;
}
.volvo-app .bottom_fix {
  padding-bottom: 20px;
}

.volvo-app .mainContent.volvoContent {
  max-height: calc(100vh - 120px);
  height: 100vh;
}
.volvo-app .mainContent.volvoContent .chatBox {
  position: absolute;
  bottom: 0;
  max-height: calc(100vh - 120px);
  overflow: auto;
  padding-bottom: 25px;
  left: 0;
  right: 0;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 50px;
  margin-bottom: 38px;
}
.volvo-app .mainContent.volvoContent .chatBox .receiver .chats .input {
  margin-bottom: 10px;
}
.volvo-app .volvo_loader {
  position: relative;
  width: 38px;
  height: 22px;
}
.volvo-app .volvo_loader span {
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0.2;
  background: #b2c6cd;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: lightUp 2s linear infinite 0.5s;
  animation: lightUp 2s linear infinite 0.5s;
}
.volvo-app .volvo_loader::before {
  content: '';
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0.2;
  background: #b2c6cd;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-animation: lightUp 2s linear infinite;
  animation: lightUp 2s linear infinite;
}
.volvo-app .volvo_loader::after {
  content: '';
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0.2;
  background: #b2c6cd;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-animation: lightUp 2s linear infinite 1s;
  animation: lightUp 2s linear infinite 1s;
}
@-webkit-keyframes lightUp {
  0% {
    opacity: 0.4;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.4;
  }
}

@keyframes lightUp {
  0% {
    opacity: 0.4;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.4;
  }
}

button.disabled {
  pointer-events: none;
}
.safeco-app .bottom_fix .btnClose:hover {
  background: #e1eef3 !important;
  color: #364347 !important;
}
.safeco-app .list_data .besideButton .btn:hover,
.safeco-app .list_data .besideButton .btn:active,
.safeco-app .list_data .besideButton .btn:focus {
  background: #ffffff !important;
  color: #02baf2 !important;
}
.safeco-app .swal2-show {
  background: #f1f1f1;
  max-width: 270px;
  border-radius: 15px;
}
.safeco-app .swal2-show .swal2-title {
  font-size: 17px;
  font-weight: 600;
  padding-top: 15px;
}
.safeco-app .swal2-show .swal2-content {
  font-size: 13px;
  float: none;
  max-width: 240px;
  margin: 0 auto;
  padding-bottom: 15px;
}
.safeco-app .swal2-show .swal2-buttonswrapper {
  border-top: 1px solid #e0e0e0;
}
.safeco-app .swal2-modal .swal2-styled {
  font-size: 17px;
  color: #0083ff;
  width: 50%;
  border-right: 1px solid #e0e0e0 !important;
  margin: 0;
  padding: 13px 32px;
}
.safeco-app .swal2-modal .swal2-confirm {
  border-right: none !important;
}
.safeco-app .swal2-modal .swal2-cancel {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.safeco-app.swal2-shown .bottom_fix {
  background: #ffffff !important;
}
.safeco-app.swal2-shown .QueAnsScreen .bottom_fix {
  background: #f7f9fa !important;
}
.safeco-app .bottom_fix .btn:hover {
  background: #02baf2;
  color: #ffffff;
}
.safeco-app .chatBox .sender {
  margin: 10px 0;
}
.safeco-app .chatBox .receiver {
  margin: 10px 0;
}
.safeco-app .getHelp {
  position: initial;
}
.chatBox .receiver .chats a.msg.cus_name {
  text-align: left;
}

.grange_policy_wrap .header .title {
  font-size: 20px;
}
.grange_policy_wrap .bottom_fix .btn {
  text-transform: none;
  padding: 10px 15px;
}
.grange_policy_wrap .policy-txt {
  color: #5e696d;
}
.grange_policy_wrap .policy-txt.small {
  padding: 0;
}
.grange_policy_wrap .policy-txt.policy_hd {
  max-width: 320px;
  margin: 0 auto;
  display: block;
  padding-bottom: 15px;
}
.grange_policy_wrap .policy-txt a {
  font-weight: 600;
}
.grange_policy_wrap .bottom_fix {
  padding: 20px;
}
.grange_policy_wrap .policy_info {
  width: 100%;
  padding: 0 20px;
}
.grange_policy_wrap .detail-box {
  margin-bottom: 20px;
}
.policy_info label {
  font-size: 13px;
  color: #364347;
  text-transform: uppercase;
  display: block;
  margin-bottom: 3px;
}
.policy_info input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border: 1px solid #d6e1e5;
  padding: 11px;
  border-radius: 4px;
  min-height: 50px;
  color: #364347;
  font-size: 20px;
}
.policy_info input::placeholder {
  color: #b2c6cd;
  font-size: 20px;
  font-weight: 400;
}
.policy_example {
  font-size: 14px;
  color: #364347;
  float: left;
}
.policy_example span {
  font-weight: 600;
}
.locate_policy a {
  font-size: 14px;
  color: #02baf2;
  float: right;
  font-weight: 600;
}
.locate_policy a:hover,
.locate_policy a:active,
.locate_policy a:focus {
  text-decoration: none;
  color: #02baf2;
}
.policy_detail {
  width: 100%;
  padding: 8px 0 20px 0;
}
.swal-locate-vin {
  backdrop-filter: blur(20px);
  background: rgba(242, 242, 242, 0.8) !important;
  border-radius: 14px !important;
  max-width: 270px;
}
.swal-locate-vin .swal2-buttonswrapper {
  border-top-color: rgba(60, 60, 67, 0.19);
}
.swal-locate-vin .swal2-styled {
  font-size: 19px;
  color: #007aff;
  padding: 8px 32px;
}
.swal-locate-vin .swal2-title {
  padding: 15px 15px 0 15px;
}
.swal-locate-vin .swal2-content {
  padding: 0 15px 15px 15px;
}
.swal-locate-policy {
  background-color: #f3f4f4 !important;
  border-radius: 10px !important;
}
.swal-locate-policy .swal2-title {
  font-size: 20px;
  font-weight: 600;
  padding: 15px 15px 0 15px;
}
.locate_policy_modal h4 {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
}
.locate_policy_modal p {
  font-size: 15px !important;
  font-weight: 400;
  color: #5e696d !important;
  max-width: 240px;
  margin: 0 auto;
}
.locate_policy_modal a {
  font-size: 15px;
  color: #02baf2;
  font-weight: 600;
}
.locate_policy_modal a:hover,
.locate_policy_modal a:focus,
.locate_policy_modal a:active {
  text-decoration: none;
  color: #02baf2;
}
.locate_policy_modal .btn,
.locate_policy_modal .btn:visited,
.locate_policy_modal .btn:active,
.locate_policy_modal .btn:focus {
  width: 100%;
  border-radius: 4px;
  padding: 10px 15px;
  background: #02baf2;
  box-sizing: border-box;
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  max-width: 257px;
  margin-bottom: 15px;
}
.locate_policy_modal .btn:hover {
  background: #e1eef3;
  color: #364347;
}
.locate_policy_modal .btn:focus {
  outline: none;
  box-shadow: none;
}
.policy_num_box {
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  width: 189px;
  height: 47px;
  text-align: center;
  margin: 0 auto;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 3px;
}
.policy_num_box span {
  font-size: 16px;
  color: #364347;
  font-weight: 400;
  display: block;
}
.policy_num_box span.policy_num_hd {
  color: #000000;
  font-weight: 700;
  margin-bottom: -5px;
}

.bottom_fix .bottom_links {
  width: 100%;
  margin: 25px 0 10px 0;
}
.bottom_fix .bottom_links a {
  font-size: 15px;
  color: $light-blue;
  font-weight: 600;
}
.bottom_fix .bottom_links a:hover,
.bottom_fix .bottom_links a:focus,
.bottom_fix .bottom_links a:active {
  text-decoration: none;
}
.bottom_fix .bottom_links .link_img {
  margin-right: 7px;
  vertical-align: bottom;
}
.bottom_drawer .drawer_base .job_number {
  position: absolute;
  top: 18px;
  left: 20px;
  font-size: 15px;
  color: $limedSpruce;
}
.pb132 {
  padding-bottom: 132px !important;
}
.pb192 {
  padding-bottom: 192px !important;
}
.pb118 {
  padding-bottom: 118px !important;
}

.search_overlay {
  background-color: #f1f1f1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.space_between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.space_between.start {
  justify-content: flex-start;
}
.space_between .form-control {
  width: 80%;
  height: 40px;
  max-width: 480px;
  font-size: 16px;
  border-color: #d6e1e5;
  -webkit-appearance: none;
}
.space_between .form-control:hover,
.space_between .form-control:active,
.space_between .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #d6e1e5;
}
.space_between .form-control::placeholder {
  font-size: 16px;
  color: $heather;
}
.outline_btn {
  background-color: #ffffff;
  border: 1px solid #d6e1e5;
  width: auto;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
  color: #364347;
  border-radius: 4px;
  text-align: center;
  padding: 5px 10px;
  //margin-left: 10px;
}
.outline_btn:hover,
.outline_btn:focus,
.outline_btn:active {
  text-decoration: none;
  color: #364347;
}
.location_info {
  font-size: 18px;
  font-weight: 400;
  color: #364347;
  line-height: 22px;
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.location_info.disabled {
  opacity: 0.3;
}
.location_info .location_hd {
  font-weight: 700;
  display: block;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box_search.box_bottom {
  position: fixed;
  background-color: #f7f9fa;
  top: auto;
  bottom: 0;
  padding: 20px;
  padding-top: 16px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.2);
}
.box_search.box_bottom .box_bottom_content {
  max-width: 600px;
  margin: 0 auto;
}
.box_search.box_bottom .box_bottom_content h2 {
  font-size: 20px;
  color: #364347;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: center;
}
.locate_img {
  position: absolute;
  right: 0;
  top: -68px;
}
.back_round {
  position: absolute;
}

.black_btn {
  display: block;
  width: 100%;
  max-width: 600px;
  border-radius: 4px;
  background-color: #364347;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  padding: 10px 12px;
  margin: 0 auto;
  margin-top: 20px;
}
.black_btn:hover,
.black_btn:active,
.black_btn:focus {
  color: #ffffff;
  background-color: #364347;
}

.box_search.location_search {
  position: relative;
  top: 0;
  background-color: #f7f9fa;
  padding: 20px;
  border-bottom: 1px solid #d6e1e5;
}
.box_search.location_search h2 {
  font-size: 20px;
  color: #364347;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.location_search_content {
  max-width: 600px;
  margin: 0 auto;
}
.location_search_content .back_icon {
  position: absolute;
}
.otherLocation .back_icon:hover,
.otherLocation .locate_img:hover {
  cursor: pointer;
}

#mapContainer .centerMarker {
  // position: fixed;
  position: relative;
  z-index: 1;
  cursor: pointer;
  width: 2px;
  height: 2px;
  background-color: red;
  top: 50%;
  left: 50%;
}
#mapContainer .centerMarker.dragComplete {
  top: calc(50% - 40px);
  left: calc(50% - 17px);
  height: 40px;
  width: 34px;
}
#mapContainer .centerMarker.dragComplete.pickUp {
  background: url('./assets/Images/pickup-marker.svg') no-repeat;
}
#mapContainer .centerMarker.dragging.pickUp {
  background: url('./assets/Images/pickup-dragging-marker.svg') no-repeat;
}
#mapContainer .centerMarker.dragComplete.dropOff {
  background: url('./assets/Images/drop-off-marker.svg') no-repeat;
}
#mapContainer .centerMarker.dragging.dropOff {
  background: url('./assets/Images/drop-off-dragging-marker.svg') no-repeat;
}
#mapContainer .centerMarker.dragging {
  width: 34px;
  height: 71px;
  top: calc(50% - 71px);
  left: calc(50% - 17px);
}
.locationDisplay {
  height: 44px;
}
.single-button .swal2-confirm.swal2-styled {
  float: none;
}
.otherLocation #mapContainer {
  height: calc(100% - 185px);
}
.fallback-error {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 25px;
}
/* Chrome, Safari, Edge, Opera */
input.numericField::-webkit-outer-spin-button,
input.numericField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.numericField[type='number'] {
  -moz-appearance: textfield;
}
.swal2-modal.swal2-show .swal2-buttonswrapper {
  display: flex !important;
}
.swal2-modal.swal2-show .swal2-buttonswrapper button {
  flex: 1 1 auto !important;
}

.swal2-buttonswrapper .swal2-confirm {
  order: 2;
}

.p_lr_20 {
  padding: 0 20px;
  position: relative;
}
.locate_link,
.locate_link:hover,
.locate_link:focus,
.locate_link:active {
  font-size: 14px;
  color: #02baf2;
  font-weight: 600;
  position: absolute;
  right: 20px;
  top: -2px;
  text-decoration: none;
}
.mb5 {
  margin-bottom: 5px !important;
}
.swal2-show .locate_vin {
  margin-top: -5px;
}
.swal2-show .locate_vin_hd {
  font-size: 17px;
  color: #000000;
  font-weight: 600;
  line-height: 22px;
}
.swal2-show .locate_vin ul {
  list-style: disc;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.swal2-show .locate_vin p,
.swal2-show .locate_vin ul li {
  font-size: 13px;
  color: #000000;
  line-height: 16px;
  max-width: 238px;
  margin: 0 auto;
}

.thanks_page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $bgcolor;
  position: relative;
}
.thanks_box {
  max-width: 320px;
  text-align: center;
}
.thanks_hd {
  font-size: 24px;
  color: $limedSpruce;
  font-weight: 700;
  margin-bottom: 5px;
}
.thanks_info {
  font-size: 17px;
  color: $limedSpruce;
  font-weight: 400;
  line-height: 25px;
}
.help_txt,
.help_txt:hover,
.help_txt:focus,
.help_txt:active {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  color: $light-blue;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 35px;
  text-decoration: none;
}
.dark-modal.swal2-shown .swal2-show {
  backdrop-filter: blur(20px);
  background: rgba(242, 242, 242, 0.8) !important;
  border-radius: 14px !important;
}
.dark-modal.swal2-shown .swal2-buttonswrapper {
  border-top-color: rgba(60, 60, 67, 0.19);
}
.dark-modal.swal2-shown .swal2-styled {
  border-right-color: rgba(60, 60, 67, 0.19) !important;
}
.dark-modal.swal2-shown .swal2-styled {
  color: #007aff;
}
.dark-modal.swal2-shown .swal2-confirm {
  font-weight: 600;
}
.dark-modal.swal2-shown .swal2-title {
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
  float: none;
}
button.btn:focus,
button.btn:active:focus {
  outline: none;
}
.QueAnsScreen.lbBg.towOverageError {
  height: calc(100vh - 170px);
}
